import { css } from '@emotion/core'
import { js2css } from 'kinetiq'

export const FrameworksWrapper = (background) => css`
  position: relative;
  z-index: 2;
  padding: 48px 0px;
`

export const FrameworksText = (base) => css`
  margin-bottom: 48px;

  & > h1 {
    ${js2css({
      ...base.typeTitle.common,
      ...base.typeTitle.largest,
      color: base.colorNeutral._1000,
      margin: '0',
      padding: '0',
    })}
    text-align: center;
  }

  ${base.MQ.phone} {
    margin-bottom: 16px;

    & > h1 {
      ${js2css({
        ...base.typeTitle.medium,
      })}
    }
  }
`

export const FrameworksContainer = (base) => css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px -12px;

  & > div {
    width: 240px;
    min-width: 240px;
    height: 128px;
    margin: 0px 16px 24px 16px;
  }

  ${base.MQ.phone} {
    margin: 0px -8px;

    & > div {
      width: 155px;
      min-width: 155px;
      height: 80px;
      margin: 0px 8px 16px 8px;
    }
  }
`
