import React, { Fragment } from 'react'
import { FrameworksWrapper, FrameworksText, FrameworksContainer } from './Frameworks.css'
import { useKinetiq } from 'kinetiq'

export const Frameworks = ({ title, frameworks }) => {
  const kinetiq = useKinetiq()
  return (
    <div css={FrameworksWrapper(kinetiq.base)}>
      <div css={FrameworksText(kinetiq.base)}>
        <h1>{title}</h1>
      </div>
      <div css={FrameworksContainer(kinetiq.base)}>
        {frameworks.map((framework, index) => (
          <Fragment key={index}>{framework}</Fragment>
        ))}
      </div>
    </div>
  )
}
