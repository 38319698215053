import React from 'react'
import { CoverBillboardLeft, FeatureChecklist } from 'kinetiq'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Features } from '../components/Features'
import { Frameworks } from '../components/Frameworks'
import { Promises } from '../components/Promises'
import { SignupModal } from '../components/SignupModal'
import { SVGImage } from '../components/SVGImage'
import { PageContent } from '../components/PageContent'

const StarshipPage = () => (
  <Layout>
    <SEO title='Starship: Simplify Your Artificial Intelligence Workflow' />
    <CoverBillboardLeft
      image=''
      title='Starship'
      tagline='Simplify your artificial intelligence workflow'
      text='Starship gives you the power and alignment to build faster and more effectively.  Collaborate with teams, manage experiments, and deploy to production without managing infrastructure.'
      rightSide={
        <FeatureChecklist
          title='Build, Train, Deploy'
          items={[
            { icon: <SVGImage variant='IconCheck' />, text: 'Collaborate with Teams' },
            { icon: <SVGImage variant='IconCheck' />, text: 'Automate Infrastructure' },
            { icon: <SVGImage variant='IconCheck' />, text: 'Train Models in Parallel' },
            { icon: <SVGImage variant='IconCheck' />, text: 'Track Experiments' },
          ]}
          button={<SignupModal />}
        />
      }
    />
    <PageContent>
      <Promises
        background='none'
        title='Designed for Developers'
        text='Starship takes you from inception to production.'
        heroes={[
          {
            image: <SVGImage variant='HeroStarshipBuild' />,
            title: 'Build',
            text:
              'Fully managed Jupyter Labs to explore and visualize your data. Develop in our browser-based IDE that makes it easy for teams to work together.',
          },
          {
            image: <SVGImage variant='HeroStarshipTrain' />,
            title: 'Train',
            text:
              'Use the Starship CLI or SDK to deploy jobs individually or in parallel across multiple GPUs. Rapidly compare and optimize your models using our powerful built-in tools.',
            alternate: true,
          },
          {
            image: <SVGImage variant='HeroStarshipDeploy' />,
            title: 'Deploy',
            text:
              'Serve your model in just a few clicks. We take care of the security, scaling, and management so you can focus on the science.',
          },
        ]}
      />
      <Features
        background='#01070D'
        title='Easily manage your artificial intelligence pipeline'
        cardsLeft={[
          {
            image: <SVGImage variant='IconStarshipTeam' />,
            title: 'Team Collaboration',
            text:
              'Automatic versioning, life-cycle management, access control, and discussion boards to keep your team on track.',
          },
          {
            image: <SVGImage variant='IconStarshipDeep' />,
            title: 'Deep Insights',
            text:
              'Visualize training metrics, system performance metrics, or create custom metrics to capture the data your team needs. Quickly compare your results to identify the most effective models.',
          },
          {
            image: <SVGImage variant='IconStarshipVersion' />,
            title: 'Version Control',
            text:
              "Experiments, source code, and datasets. Automatically logged changes, so it's easy to track and reproduce models.",
          },
        ]}
        cardsRight={[
          {
            image: <SVGImage variant='IconStarshipInfrastructure' />,
            title: 'Infrastructure Agnostic',
            text:
              "Use Qoor's low-cost artificial intelligence infrastructure or deploy to your on-prem clusters. Manage your resources regardless of your data center strategy.",
          },
          {
            image: <SVGImage variant='IconStarshipDeveloper' />,
            title: 'Simplified Developer Tools',
            text:
              'Deploy jobs or models quickly using the Starship CLI or automate your artificial intelligence pipelines with the Starship API. We make automation seamless.',
          },
        ]}
      />
      <Frameworks
        title='Your Favorite Frameworks'
        frameworks={[
          <div>
            <SVGImage variant='FrameworkTensorflow' />
          </div>,
          <div>
            <SVGImage variant='FrameworkPytorch' />
          </div>,
          <div>
            <SVGImage variant='FrameworkKeras' />
          </div>,
          <div>
            <SVGImage variant='FrameworkPycaffe' />
          </div>,
          <div>
            <SVGImage variant='FrameworkSciKitLearn' />
          </div>,
        ]}
      />
    </PageContent>
  </Layout>
)

export default StarshipPage
